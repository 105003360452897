/**
 * An enum for providing set Regex Patterns to validate inputs
 */
export enum PatternEnum {
  Phone = 'p',
  Email = 'e',
  Zip = 'z',
  Zip_US = 'zus',
  Zip_CA = 'zca',
  Url = 'u',
  Integer = 'i',
  Number = 'n',
  Currency = '$',
  Decimal1 = 'd1',
  Decimal2 = 'd2',
  Decimal3 = 'd3',
  Alpha = 'a',
}

/**
 * An array of possible Regex Patterns (PatternEnum)
 */
export const PatternArray = Object.values(PatternEnum);

/**
 * Gets the regex associated with the Pattern (PatternEnum)
 */
export const Pattern = {
  [PatternEnum.Phone]: /^(\+(\d{1,3}|(\d{1,2}-\d{1,4})?)?-)?\d{3}-\d{3}-\d{4}( ext\d{1,6})?$/,
  [PatternEnum.Email]: /^[\w\.+-]+@[\w-]+\.\w+(\.\w+)?$/,
  [PatternEnum.Zip]: /^(\d{5}(-\d{4})?$)|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
  [PatternEnum.Zip_US]: /^\d{5}(-\d{4})?$/,
  [PatternEnum.Zip_CA]: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  [PatternEnum.Url]: /^(HTTPS?:\/\/)?(WWW.)?(\w+(-\w+)*\.)+\w+\S*$/i,
  [PatternEnum.Integer]: /^\-?\d*$/,
  [PatternEnum.Number]: /^\-?\d*\.?\d*$/,
  [PatternEnum.Currency]: /^\-?\d+(\.?\d{1,2})?$/,
  [PatternEnum.Decimal1]: /^\-?\d+(\.?\d)?$/,
  [PatternEnum.Decimal2]: /^\-?\d+(\.?\d{1,2})?$/,
  [PatternEnum.Decimal3]: /^\-?\d+(\.?\d{1,3})?$/,
  [PatternEnum.Alpha]: /^[A-Z\u00C0-\u017F ]*$/i
}

/**
 * Provides a legible name and a description for all Patterns (PatternEnum)
 */
export const PatternDetails = {
  [PatternEnum.Phone]: {
    name: 'Phone',
    desc: 'must match the format: 123-456-7890'
  },
  [PatternEnum.Email]: {
    name: 'Email',
    desc: 'must be a valid email'
  },
  [PatternEnum.Zip]: {
    name: 'Zip Code',
    desc: 'must be a valid postal code'
  },
  [PatternEnum.Zip_US]: {
    name: 'US Zip',
    desc: 'must be a valid US zip code'
  },
  [PatternEnum.Zip_CA]: {
    name: 'CA Zip',
    desc: 'must be a valid postal code'
  },
  [PatternEnum.Url]: {
    name: 'Url',
    desc: 'must be a valid website url'
  },
  [PatternEnum.Integer]: {
    name: 'Integer',
    desc: 'must be a whole number'
  },
  [PatternEnum.Number]: {
    name: 'Number',
    desc: 'must be a number'
  },
  [PatternEnum.Currency]: {
    name: 'Currency',
    desc: 'must match the format: ######.##'
  },
  [PatternEnum.Decimal1]: {
    name: '1 Decimal',
    desc: 'must match the format: ######.#'
  },
  [PatternEnum.Decimal2]: {
    name: '2 Decimals',
    desc: 'must match the format: ######.##'
  },
  [PatternEnum.Decimal3]: {
    name: '3 Decimals',
    desc: 'must match the format: ######.###'
  },
  [PatternEnum.Alpha]: {
    name: 'Alpha Characters',
    desc: 'must not contain numbers'
  }
}
