import { environment } from "../../environments/environment";
import { LookupModel } from "../_model/metadata/lookup.model";

/***************************************************************************/
/* GENERAL                                                                 */
/***************************************************************************/
/**
 * Checks for if a value exists. Will properly handle falsy values.
 */
export function exists(value): boolean {
  return value !== null && value !== undefined && value !== '';
}
/**
 * Checks for if a value is an array.
 */
export function isArray(value): boolean {
  return value && value.constructor === Array;
}
/**
 * Checks for if a value is an object.
 */
export function isObject(value): boolean {
  return value && value.constructor === Object;
}
/**
 * Checks for if a value is a date.
 */
export function isDate(value): boolean {
  return value && value.constructor === Date;
}

/**
 * Detects the date string format "yyyy-mm-ddThh:mm:ss.mssZ".
 * The time portion (T and further) is is optional.
 */
export function isISOString(string: string): boolean {
  return /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?Z?)?$/.test(string);
}

/**
 * Detects a string (or number) in the format "yyyy".
 * Definitely leads to false positives if not used wisely.
 */
export function isProbablyYear(string: string): boolean {
  return /^\d{4}$/.test(string);
}

/**
 * Compares two arrays' contents against each other to see if they're the same.
 * Only designed for string[] at the moment.
 */
export function arraysEqual(array1: string[], array2: string[]): boolean {
  if (array1.length !== array2.length) return false;
  for (let el of array1) {
    if (!array2.includes(el)) {
      return false;
    }
  }
  return true;
}

export function handleChunkLoadError(error: any, route: string) {
  if (!!error && /ChunkLoadError: Loading chunk [\w]+ failed\./.test(error)) {
    window.location.href = environment.url + route.startsWith('/') ? route : ('/' + route);
  } else throw error;
}

/***************************************************************************/
/* SORTING                                                                 */
/***************************************************************************/
/**
 * Sorts the supplied list with the supplied key in the supplied direction, then returns it
 */
export function sort(list: any[], key: string, sortOrder: 'ASC' | 'DESC'): typeof list {
  return list.sort((a, b) => compare(a[key], b[key], sortOrder));
}

/**
 * Standard sort comparison ternary
 */
function compare(a: any, b: any, sortOrder: 'ASC' | 'DESC'): number {
  return (a == b ? 0 : a > b ? 1 : -1) * (sortOrder === 'ASC' ? 1 : -1);
}

/***************************************************************************/
/* FORMATTING                                                              */
/***************************************************************************/
const months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
/**
 * Formats a date to: "Month DayNumber, YearNumber"
 */
export function formatDate(date: any): string {
  let d = new Date(date);
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

/**
 * Formats a date to: "Month DayNumber, YearNumber HH:MM AM/PM"
 */
export function formatDateTime(date: any, seconds?: boolean): string {
  let d = new Date(date);
  let hours = d.getHours();
  let AMPM = 'AM';
  if (hours >= 12) AMPM = 'PM';
  if (hours > 12) hours -= 12;
  else if (hours === 0) hours = 12;
  let mins: any = d.getMinutes();
  if (mins < 10) mins = '0' + mins;
  let secs: any = d.getSeconds();
  if (secs < 10) secs = '0' + secs;
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} ${hours}:${mins}${seconds ? ':' + secs : ''} ${AMPM}`;
}

/**
 * Formats a date to: "HH:MM AM/PM"
 */
export function formatTime(date: any, seconds?: boolean): string {
  let d = new Date(date);
  let hours = d.getHours();
  let AMPM = 'AM';
  if (hours >= 12) AMPM = 'PM';
  if (hours > 12) hours -= 12;
  else if (hours === 0) hours = 12;
  let mins: any = d.getMinutes();
  if (mins < 10) mins = '0' + mins;
  let secs: any = d.getSeconds();
  if (secs < 10) secs = '0' + secs;
  return `${hours}:${mins}${seconds ? ':' + secs : ''} ${AMPM}`;
}

/**
 * Formats a number/string to: "$dollars.cents"
 */
export function formatCurrency(number: number | string): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(Number(number));
}

const normalRegex: RegExp = /([^\w]|_)*/g;
/**
 * Normalizes a string to remove all non-alphanumeric characters. Helps a lot when searching against data
 */
export function normalize(string: string): string {
  return (string || '').toUpperCase().replace(normalRegex, '');
}

/**
 * Converts a camelCase string to a PascalCase string
 */
export function camelToPascal(string: string): string {
  return string.substring(0, 1).toUpperCase() + string.substring(1);
}

/**
 * Converts a PascalCase string to a camelCase string
 */
export function pascalToCamel(string: string): string {
  return string.substring(0, 1).toLowerCase() + string.substring(1);
}

/**
 * Converts all the keys in an object to PascalCase
 */
export function pascalizeObject(object: any): any {
  let pascalObject = {};
  for (let key in object)
    pascalObject[camelToPascal(key)] = object[key];
  return pascalObject;
}

/**
 * Converts all the keys in an object to camelCase
 */
export function camelizeObject(object: any): any {
  let camelObject = {};
  for (let key in object)
    camelObject[pascalToCamel(key)] = object[key];
  return camelObject;
}

/**
 * Attempts to format a name properly
 */
export function formatName(name?: string): string {
  return name.toLowerCase().trim()
            .split(' ').filter(part => !!part).map(part => part[0].toUpperCase() + part.substring(1)).join(' ')
            .split('-').filter(part => !!part).map(part => part[0].toUpperCase() + part.substring(1)).join('-')
            .split('.').filter(part => !!part).map(part => part[0].toUpperCase() + part.substring(1)).join('.');
}

/**
 * Replaces the end of the string supplied with the suffix, appends otherwise.
 */
export function replaceSuffix(string: string, replace: string, suffix: string): string {
  return (string || '').replace(replace || '', '') + suffix || '';
}

/**
 * Builds address line 1, 2, and also a full address string.
 */
export function buildAddresses(
  streetNumber: string, streetName: string, streetSuffix: string, streetDirSuffix: string, unitNumber: string,
  city: string, stateOrProvince: string, postalCode: string, country?: string, lookups?: LookupModel[]
): {
  address1: string,
  address2: string,
  fullAddress: string,
} {
  let addresses = {
    address1: null,
    address2: null,
    fullAddress: null,
  };
  addresses.address1 = buildAddress1(streetNumber, streetName, streetSuffix, streetDirSuffix, unitNumber);
  addresses.address2 = buildAddress2(city, stateOrProvince, postalCode, country, lookups);
  addresses.fullAddress = addresses.address1 + ' ' + addresses.address2;
  return addresses;
}

/**
 * Builds address line 1
 */
function buildAddress1(streetNumber: string, streetName: string, streetSuffix: string, streetDirSuffix: string, unitNumber: string) {
  let address = '';
  address += exists(streetNumber) ? streetNumber : '';
  address += exists(streetName) ? ' ' + streetName : '';
  address += exists(streetSuffix) && normalize(streetSuffix) !== normalize('N/A') ? ' ' + streetSuffix : '';
  address += exists(streetDirSuffix) ? ' ' + streetDirSuffix : '';
  address += exists(unitNumber) ? ', ' + unitNumber : '';
  return address;
}

/**
 * Builds address line 2
 */
function buildAddress2(city?: string, stateOrProvince?: string, postalCode?: string, country?: string, lookups?: LookupModel[]) {
  let address = '';
  if (city && lookups?.length) {
    city = lookups.find(l => l.lookupValue === city)?.lookupDisplayName || city;
  }
  city = city?.replace(/Toronto(\ )?[CEW]\d{2}/, 'Toronto');
  city = city?.replace(/(\ )?and(\ )?Area/i, '');
  address += exists(city) ? city : '';
  if (stateOrProvince && lookups?.length) {
    stateOrProvince = lookups.find(l => l.lookupValue === stateOrProvince)?.lookupDisplayName || stateOrProvince;
  }
  address += exists(stateOrProvince) ? ((exists(city) ? ', ' : '') + stateOrProvince) : '';
  address += exists(postalCode) ? ' ' + postalCode : '';
  address += exists(country) ? ', ' + country : '';
  return address;
}
