import { BaseModel } from "../base.model";
import { AppearanceEnum } from "src/app/_enum/form/appearance.enum";
import { GroupModel } from "./group.model";
import { RuleModel } from "../rule/rule.model";
import { ColumnEnum } from "../../_enum/form/column.enum";
import { StatusEnum } from "../../_enum/form/status.enum";
import { ConfigsModel } from "./configs.model";
import { MiniFormModel } from "./mini-form.model";
import { TriggerEnum } from "../../_enum/rule/trigger.enum";
import { sortRules } from "../../_helper/rule.helper";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { InputModel } from "./input.model";

/**
 * A class that provides all of the expected shared values of all form elements.
 */
export class FormModel extends BaseModel {

  public id: number = null;
  public description: string = null;
  public appearance: MatFormFieldAppearance = AppearanceEnum.Outline;
  public columns: ColumnEnum = ColumnEnum.Two;
  public rules: RuleModel[] = [];
  public sections: GroupModel[] = [];
  public configs: ConfigsModel = null;
  public statusForm: MiniFormModel = null;
  public version: number = null;
  public status: StatusEnum = StatusEnum.Draft;
  public customerName: string = 'form';
  public resourceName: string = 'property';

  constructor(model?: Partial<FormModel>) {
    super();
    this.overwrite(model);
    this.cleanRules();
    sortRules(this);
  }

  public overwrite(model: Partial<FormModel>, ...exclude: string[]) {
    super.overwrite(model, 'rules', 'sections', 'configs', 'statusForm', ...exclude);
    if (model?.rules?.length) this.rules = model.rules.map(rule => new RuleModel(rule));
    if (model?.sections?.length) this.sections = model.sections.map(section => new GroupModel(section));
    this.configs = new ConfigsModel(model?.configs);
    this.statusForm = new MiniFormModel(model?.statusForm ? {
      form: 'status',
      appearance: this.appearance,
      sections: [model.statusForm.sections[0]],
      rules: [...model.statusForm.rules]
    } : {
      form: 'status',
      sections: [<GroupModel>{
        label: 'Status Change',
        mapping: '-1',
        order: -1,
        columns: ColumnEnum.One
    }]});
  }

  private cleanRules() {
    //make sure certain triggers' tValues are null
    //there was a bug at one point that would populate them with a 0
    for (let rule of this.rules) {
      if ([ TriggerEnum.Init, TriggerEnum.Changes, TriggerEnum.Exists, TriggerEnum.NotExists ].includes(rule.trigger)) {
        rule.tValue = null;
      }
    }
  }

  /**
   * Creates a mini-form based on the create config
   */
  public generateCreateForm(): MiniFormModel {
    return new MiniFormModel({
      form: 'create',
      appearance: this.appearance,
      sections: [
        new GroupModel({
          label: 'Basic Information',
          mapping: '-1',
          order: -1,
          columns: ColumnEnum.Two,
          elements: Object.keys(this.configs.create.basic).map(key =>
                      new InputModel(Object.assign(this.configs.create.basic[key], {
                        width: ['ListOfficeKey','ListAgentKey','TransactionType']
                                .includes(this.configs.create.basic[key].mapping) ?
                                  ColumnEnum.Two : ColumnEnum.One
                      })))
        }),
        new GroupModel({
          label: 'Address',
          mapping: '-1',
          order: -1,
          columns: ColumnEnum.Two,
          elements: Object.keys(this.configs.create.address).map(key =>
                      new InputModel(this.configs.create.address[key]))
        }),
      ],
      rules: [
        // MAYBE: do we want to pull rules from the main form?
      ]
    });
  }
}
