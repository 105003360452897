import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, from, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { compact } from "../_helper/form.helper";
import { FormModel } from "../_model/form/form.model";
import { HttpService } from "./http.service";
import { IDBTable, IndexedDBService } from "./indexed-db.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";
import { StatusEnum } from "../_enum/form/status.enum";
import { exists } from "../_helper/util.helper";

@Injectable({
  providedIn: 'root'
})
export class FormService extends HttpService {

  private table = IDBTable.Form as const;
  private url = environment.apiUrl + 'forms';

  //forms list
  private forms: FormModel[] = [];
  private forms$: BehaviorSubject<FormModel[]> = new BehaviorSubject(null);
  private formsTimestamp: number = 0;

  //current form
  private gettingForm: { [resource: string]: boolean } = {};
  private form: { [resource: string]: FormModel } = {};
  private form$: { [resource: string]: Subject<FormModel> } = {};
  private fakeForm: FormModel = null;

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private idb: IndexedDBService
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Clears all of the data from the FormService
   */
  public clear() {
    //all forms
    this.forms = [];
    this.forms$.next(null);
    this.forms$.complete();
    this.forms$ = new BehaviorSubject(null);
    this.formsTimestamp = 0;
    //current form
    this.gettingForm = {};
    this.form = {};
    for (let resource in this.form$) {
      this.gettingForm[resource] = false;
      this.form$[resource].next(null);
      this.form$[resource].complete();
    }
    this.form$ = {};
    this.fakeForm = null;
  }

  /**************************************************************************************/
  /** LOCAL FORM EDITING                                                                */
  /**************************************************************************************/

  /**
   * Build the local key for the local database
   */
  private buildLocalKey(key: string, resource: string, version?: number): string {
    return (key || '')
          + (resource ? '-'+resource : '')
          + (version ? '-'+version : '');
  }

  /**
   * Gets the local working version of the form.
   * Falls back to the server if it cannot be found.
   */
  public async getLocal(key?: string, resource?: string, version?: number): Promise<FormModel> {
    //check idb
    let localKey = this.buildLocalKey(key, resource, version);
    let localForm = await this.idb.get(this.table, localKey);
    if (localForm) return new FormModel(localForm);
    //fallback to server
    if (!localForm) localForm = await this.getSpecific(key, resource, version) || null;
    return localForm;
  }

  /**
   * Saves the supplied form locally
   */
  public saveLocal(form?: FormModel): Promise<void> {
    if (!form) return Promise.resolve();
    let localKey = this.buildLocalKey(form.customerName, form.resourceName, form.version || 0);
    return this._saveLocal(form, localKey);
  }

  /**
   * Deletes the local version of the form
   */
  public deleteLocal(key?: string, resource?: string, version?: number): Promise<void> {
    let localKey = this.buildLocalKey(key, resource, version);
    return this.idb.delete(this.table, localKey);
  }

  /**
   * Saves the supplied form to the local IndexedDB.
   * Also cleans up old local versions of the form so too many don't build up in storage
   */
  private _saveLocal(form: FormModel, key: string): Promise<void> {
    return this.idb.save(this.table, key, compact(form.clone())).then(() => {
      this.idb.getAll(this.table).then(_localForms => {
        //cleanup old forms
        if (_localForms && _localForms.length) {
          let localCustomerForms = _localForms.filter(_form => _form.customerName === form.customerName);
          let resources = [...new Set(localCustomerForms.map(f => f.resourceName))];
          for (let resource of resources) {
            let localCustomerResourceForms = localCustomerForms.filter(f =>
              f.resourceName === resource ||
              // TODO: the line below is purely to clean up from when we didn't
              // have resource and can probably be removed at some point
              resource === 'property' && !f.resourceName
            );
            let maxVersion = 0;
            for (let _form of localCustomerResourceForms) if (_form.version > maxVersion) maxVersion = _form.version;
            let minVersionToKeep = maxVersion - 5; //5 past versions per-customer per-resource seems reasonable to keep locally
            for (let _form of localCustomerResourceForms) if (_form.version < minVersionToKeep) {
              this.idb.delete(this.table, this.buildLocalKey(_form.customerName, _form.resourceName, _form.version));
            }
          }
        }
      }).catch(error => {
        //silent error
        environment.error(error);
      });
    });
  }

  /**************************************************************************************/
  /** SERVER FORM                                                                       */
  /**************************************************************************************/

  /**
   * Gets all forms for a specific customer.
   * !! NOTE !!
   * There is a significant amount of data missing from these, only intended to be used in lists.
   */
  public getList(customer: string): Observable<FormModel[]> {
    if (!this.forms?.length || !this.formsTimestamp || new Date().getTime() - this.formsTimestamp >= 900000) { //15m cache time, why not
      this.GET(this.url + '/all', { customerName: customer }).subscribe(_forms => {
        if (_forms) {
          this.forms = _forms.map(f => new FormModel(f)).sort((a, b) => a.version > b.version ? 1 : -1);
          this.formsTimestamp = new Date().getTime();
          this.forms$.next(this.forms);
          // update local form statuses in idb
          this.idb.getAll(this.table).then(_localForms => {
            for (let localForm of _localForms) {
              let form = this.forms.find(f => f.id === localForm.id);
              if (form && form.status !== localForm.status) {
                localForm.status = form.status;
                let localKey = this.buildLocalKey(localForm.customerName, localForm.resourceName, localForm.version);
                this.idb.save(this.table, localKey, localForm);
              }
            }
          });
        }
      }, error => { //no fallback, error out
        //silent error
        environment.error(error);
      });
    }
    return this.forms$.asObservable();
  }

  /**
   * Updates a single form inside the cached list of forms from the server.
   */
  private updateList(form: FormModel) {
    let index = this.forms.findIndex(f => f.id === form.id)
    if (index === -1) this.forms.push(form);
    else this.forms[index] = form;
  }

  /**
   * Gets the currently active form for a customer/resource.
   */
  public get(customer: string, resource: string): Observable<FormModel> {
    //if there's a fake active form, deliver it regardless
    if (this.fakeForm) return of(this.fakeForm);
    //check if we have a cached form in the idb
    if (!this.form[resource]) {
      let localKey = this.buildLocalKey('form', resource);
      return from(this.idb.get(this.table, localKey)).pipe(switchMap(_form => {
        if (_form) this.form[resource] = new FormModel(_form);
        return this._checkOrGet(customer, resource);
      }));
    }
    return this._checkOrGet(customer, resource);
  }

  /**
   * Checks the cached form against the server,
   * or if not cached gets the form.
   */
  private _checkOrGet(customer: string, resource: string) {
    if (!this.gettingForm[resource]) {
      this.gettingForm[resource] = true;
      if (!this.form$[resource]) this.form$[resource] = new Subject();
      //if we have a cached form, check for update
      if (this.form[resource] && exists(this.form[resource].version)) this._check(customer, resource);
      //otherwise, fetch it and cache it
      else this._get(customer, resource);
    }
    return this.form$[resource].asObservable();
  }

  /**
   * Checks the cached form against the server.
   * An updated form will be delivered if the cached form is out-of-date.
   */
  private _check(customer: string, resource: string) {
    this.GET(this.url + '/check/' + this.form[resource].version, { customerName: customer, resourceName: resource }).toPromise().then(update => {
      if (update) { //save the updated form
        this.form[resource] = new FormModel(update);
        let localKey = this.buildLocalKey('form', resource);
        this._saveLocal(this.form[resource], localKey);
      }
      this.gettingForm[resource] = false;
      this.form$[resource].next(this.form[resource].clone());
    }).catch(_error => { /* no cares, we have a cached version */ });
  }

  /**
   * Gets the current active form from the server.
   */
  private _get(customer: string, resource: string) {
    this.GET(this.url, { customerName: customer, resourceName: resource, status: StatusEnum.Active }).toPromise().then(_form => {
      this.form[resource] = new FormModel(_form);
      let localKey = this.buildLocalKey('form', resource);
      this._saveLocal(this.form[resource], localKey);
      this.gettingForm[resource] = false;
      this.form$[resource].next(this.form[resource]);
      this.updateList(this.form[resource].clone());
    }).catch(error => this.form$[resource].error(error));
  }


  /**
   * Returns the specified form version for the specified customer from the server.
   * Intentionally bypasses the cache. Does not use the fake form.
   */
  public getSpecific(customer: string, resource: string, version: number): Promise<FormModel> {
    return this.GET(this.url, { customerName: customer, resourceName: resource, version: version }).toPromise().then(_form => {
      this.validateModel('FormModel', _form, new FormModel(), true, true);
      let form = new FormModel(_form);
      this.updateList(form);
      return form;
    });
  }

  /**
   * Returns the currently active form for the specified customer from the server.
   * Intentionally bypasses the cache. Does not use the fake form.
   */
  public getActive(customer: string, resource: string): Promise<FormModel> {
    return this.GET(this.url, { customerName: customer, resourceName: resource, status: StatusEnum.Active }).toPromise().then(_form => {
      this.validateModel('FormModel', _form, new FormModel(), true, true);
      let form = new FormModel(_form);
      this.updateList(form);
      return form;
    });
  }

  /**
   * Adds a new form to the server
   */
  public add(form: FormModel): Promise<FormModel> {
    return this.POST(this.url, compact(form)).toPromise().then(_form => {
      this.validateModel('FormModel', _form, new FormModel(), true, true);
      let form = new FormModel(_form);
      this.updateList(form);
      return form;
    });
  }

  /**
   * Saves an existing form to the server
   */
  public save(form: FormModel): Promise<void> {
    return this.PUT(this.url + '/' + form.id, compact(form)).pipe(map(_form => {
        this.saveLocal(new FormModel(_form));
        this.validateModel('FormModel', _form, new FormModel(), true, true)
    })).toPromise();
  }

  /**
   * Sets the form as the active form
   */
  public activate(form: FormModel): Promise<FormModel> {
    return this.PUT(this.url + '/' + form.id + '/makeActive', null).toPromise().then(_form => {
      this.saveLocal(new FormModel(_form));
      this.validateModel('FormModel', _form, new FormModel(), true, true);
      // update cached forms statuses
      for (let cachedForm of this.forms) {
        if (
          cachedForm.customerName === _form.customerName &&
          cachedForm.resourceName === _form.resourceName &&
          cachedForm.status === StatusEnum.Active &&
          cachedForm.id !== _form.id
        ) {
          cachedForm.status = StatusEnum.Archived;
        } else if (cachedForm.id === _form.id) {
          cachedForm.status = StatusEnum.Active;
        }
      }
      // update local form statuses in idb
      this.idb.getAll(this.table).then(_localForms => {
        for (let localForm of _localForms) {
          if (
            localForm.customerName === _form.customerName &&
            localForm.resourceName === _form.resourceName &&
            localForm.status === StatusEnum.Active &&
            localForm.id !== _form.id
          ) {
            localForm.status = StatusEnum.Archived;
            let localKey = this.buildLocalKey(localForm.customerName, localForm.resourceName, localForm.version);
            this.idb.save(this.table, localKey, localForm);
          }
        }
      });
      return new FormModel(_form);
    });
  }

  /**
   * Fakes the active form, for testing with listings.
   * Can be called with no form to clear the fake active form
   */
  public fakeActivate(form?: FormModel) {
    this.fakeForm = form?.clone();
  }
}
